<template>
 
 <!-- <div class="navbar" /> -->
 <hr>
  <div class="container mb-4">
    <div class="row text-center">
      <h2 class="text-primary mb-3">
        Etho Protocol is a Proud Founding Member of the BPSAA
      </h2>
      <hr class="divider my-4" />
      <div>
        <a href="https://bpsaa.vision/" target="_blank" class="d-block mx-auto">
          <img
            src="@/assets/imgs/etho/bpsaa.png"
            alt="bpsaa"
            class="img-fluid"
            style="max-width: 100%; transition: all 400ms;"
          />
        </a>
      </div>
    </div>

    <div class="row text-center mt-4">
      <div class="logo-wheel">
        <div class="logos-wrapper">
          <!-- First set of logos -->
          <div class="logo-container" v-for="partner in partners" :key="partner.name">
            <a :href="partner.link" target="_blank">
              <img
                :src="getImagePath(partner.imgSrc)"
                :alt="partner.name"
                class="img-fluid logo-img"
              />
            </a>
          </div>
          <!-- Second set of logos (repeated) -->
          <div class="logo-container" v-for="partner in partners" :key="partner.name + '-2'">
            <a :href="partner.link" target="_blank">
              <img
                :src="getImagePath(partner.imgSrc)"
                :alt="partner.name"
                class="img-fluid logo-img"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




  
<script>
export default {
  name: "PartnerSection",
  data() {
    return {
      partners: [
        {
          name: "pirate",
          link: "https://pirate.black/",
          imgSrc: "pirate.png",
        },
        {
          name: "conceal",
          link: "https://conceal.network/",
          imgSrc: "conceal1-300x100-1.png",
        },
        {
          name: "dragon",
          link: "https://dragonchain.com/",
          imgSrc: "dragon.png",
        },
        {
          name: "ergo",
          link: "https://ergoplatform.org/",
          imgSrc: "ergo.png",
        },
        {
          name: "komodo",
          link: "https://komodoplatform.com/",
          imgSrc: "komodo.png",
        },
        {
          name: "signum",
          link: "https://www.signum.network/",
          imgSrc: "Signum_Logo-300x70.png",
        },
        {
          name: "handshake",
          link: "https://handshake.org/",
          imgSrc: "handshake_logo-dark.svg",
        },
      ],
    };
  },
  methods: {
    getImagePath(image) {
      return require(`@/assets/imgs/etho/${image}`);
    },
  },
};
</script>



<style scoped>

.navbar {
  background: linear-gradient(270deg, #2781b1, #33325d) !important;
  height:32px;
}


.text-primary {
  color: #010042 !important;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 500;
  text-transform: capitalize;
}

.divider {
  border-top: 3px solid #971b45;
  width: 10%;
  margin: 0 auto;
}


/* Logo wheel container */
.logo-wheel {
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* Wrapper that holds the logos */
.logos-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: scroll 20s linear infinite; /* Control speed with duration */
}

/* Individual logo container */
.logo-container {
  flex: 0 0 auto; /* Prevent logos from shrinking */
  margin: 0 15px; /* Space between logos */
}

.logo-img {
  width: 200px; /* Adjust size as needed */
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.logo-img:hover {
  transform: scale(1.2); /* Zoom effect on hover */
}

/* Scrolling animation with repetition */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Scroll halfway, showing the second half */
  }
}

/* Ensure logos repeat seamlessly */
.logos-wrapper {
  display: flex;
  animation: scroll 8s linear infinite;
}

.logos-wrapper::before {
  content: '';
  flex-shrink: 0;
}

.logos-wrapper::after {
  content: '';
  flex-shrink: 0;
}

.logo-wheel {
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.logo-wheel .logos-wrapper {
  display: flex;
  animation: scroll 8s linear infinite;
  width: max-content; /* Ensure enough space for repetition */
}

.logo-container {
  min-width: 200px;
}

.logos-wrapper:hover {
  animation-play-state: paused;
}
</style>
