

import _BridgeAssistO from '/abis/BridgeAssistO.json'
import _Token from '/abis/Token.json'
import _Oracle from '/abis/oracle_contract.json'
import _Signer from '/abis/multisig_signer_contract.json'

/* eslint-disable */

// GLOBAL.js
const GLOBAL = {
  ETHO_ETH_MIN_DEPOSIT : 1, // in dollars
  ETH_MIN_WITHDRAWAL : 0.5, // in dollars
  ETH_PRICE_URL : "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=eth",
  BridgeAssistO : _BridgeAssistO,
  Token : _Token,
  Oracle : _Oracle,
  Signer : _Signer,
  etho20Logo: require("../assets/imgs/etho/etho20.png"),
  EXPLORER_ETHO_TX: "https://explorer.ethoprotocol.com/tx/",
  EXPLORER_ETHO_ADDRESS: "https://explorer.ethoprotocol.com/address/",
  address_ORCL: "0x1509c8F22b7a14bab4EA829324BFcc44371A5667",
  address_DEVFUND: "0xBA57dFe21F78F921F53B83fFE1958Bbab50F6b46",
  address_SIGNER_authority: "0xfb7f3074B16E5B2eF73C775ADCD3bf48B8cFF959",
  provider_ETHO: 'https://rpc.ethoprotocol.com',
  mainnet_chainID: 1313114,
}

// const address_VERIFIER_ETH = '0x488f9b27e7a7F8a74437DBb933E014F428E44343';
// const address_SIGNER_BSC = '0x8b52F46A52D86C131222EE14167Da6a847bdB84a';
// const address_VERIFIER_BSC = '0x488f9b27e7a7F8a74437DBb933E014F428E44343';
// const address_SIGNER_BASE = '0xB6DbA1d3ED4938377565E84e259AB50336EF23E7';
// const address_VERIFIER_BASE = '0x15A0945408eC8Aa11d95632fB5567AB65092B8DD';
const networks = {
  ETH: {
    IDENT: "ETH",
    EXPLORER_BSC_TOKEN: "https://etherscan.io/token/",
    EXPLORER_ETH_TX: "https://etherscan.io/tx/",
    EXPLORER_BSC_ADDRESS: "https://etherscan.io/address/",
    EXPLORER_ETHO_TX: GLOBAL.EXPLORER_ETHO_TX,
    EXPLORER_ETHO_ADDRESS: GLOBAL.EXPLORER_ETHO_ADDRESS,
    pancakeSwapLogo: require('../assets/imgs/etho/uniswap.png'),
    etherscanLogo: require('../assets/imgs/etho/etherscan-logo.png'),
    tokenLogo: require("../assets/imgs/etho/eth-logo.svg"),
    erc20Logo: require("../assets/imgs/etho/erc20.png"),
    DEX_ADDRESS: "https://app.uniswap.org/explore/tokens/ethereum/0x0b5326da634f9270fb84481dd6f94d3dc2ca7096",
    // DEX_ADDRESS: "https://info.uniswap.org/#/pools/0xdb6a79d7df4cb14256cd37426c62804a241fb5c2",
    TKN_NETWORK_NAME: "ETH Network",
    ETH_PRICE_URL: "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=eth",
    address_TKN: "0x0b5326Da634f9270FB84481DD6F94d3dC2cA7096",
    address_BA: "0x370f083A3c4794DDBb49cfB4E7C7e09B37d57545",
    address_SIGNER: "0x488f9b27e7a7F8a74437DBb933E014F428E44343",
    address_VERIFIER: "0x488f9b27e7a7F8a74437DBb933E014F428E44343",
    DEV_WALLET : '0x1509c8F22b7a14bab4EA829324BFcc44371A5667',
    address_ORCL: GLOBAL.address_ORCL,
    address_SIGNER_authority: GLOBAL.address_SIGNER_authority,
    address_DEVFUND: GLOBAL.address_DEVFUND,
    TOKEN_CONFIRMATIONS: 12,
    ETHO_CONFIRMATIONS: 22,
    token_chainID: 1,
    mainnet_chainID: GLOBAL.mainnet_chainID,
    provider_TOKEN: 'https://mainnet.infura.io/v3/a289a5a7fb754ae8aa8fa1915f87eda0',
    provider_ETHO: GLOBAL.provider_ETHO,
    ETHO_ETH_MIN_DEPOSIT: 5,
    ETH_MIN_WITHDRAWAL: 1,
  },
  BASE: {
    IDENT: "BASE",
    EXPLORER_BSC_TOKEN: "https://basescan.org/token/",
    EXPLORER_ETH_TX: "https://basescan.org/tx/",
    EXPLORER_BSC_ADDRESS: "https://basescan.org/address/",
    EXPLORER_ETHO_TX: GLOBAL.EXPLORER_ETHO_TX,
    EXPLORER_ETHO_ADDRESS: GLOBAL.EXPLORER_ETHO_ADDRESS,
    pancakeSwapLogo: require('../assets/imgs/etho/uniswap.png'),
    etherscanLogo: require('../assets/imgs/etho/basescan-logo.svg'),
    tokenLogo: require("../assets/imgs/etho/base.png"),
    erc20Logo: require("../assets/imgs/etho/base20.png"),
    DEX_ADDRESS: "https://app.uniswap.org/explore/tokens/base/0x8b52F46A52D86C131222EE14167Da6a847bdB84a",
    // DEX_ADDRESS: "https://info.uniswap.org/#/pools/0xdb6a79d7df4cb14256cd37426c62804a241fb5c2",
    TKN_NETWORK_NAME: "BASE Network",
    ETH_PRICE_URL: "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=eth",
    address_TKN: "0x8b52F46A52D86C131222EE14167Da6a847bdB84a",
    address_BA: "0xF3c40b22e2505ad2D7C9254b5479E3e9046F0f16",
    address_SIGNER: "0xB6DbA1d3ED4938377565E84e259AB50336EF23E7",
    address_VERIFIER: "0x15A0945408eC8Aa11d95632fB5567AB65092B8DD",
    DEV_WALLET : '0x1509c8F22b7a14bab4EA829324BFcc44371A5667',
    address_ORCL: GLOBAL.address_ORCL,
    address_SIGNER_authority: GLOBAL.address_SIGNER_authority,
    address_DEVFUND: GLOBAL.address_DEVFUND,
    TOKEN_CONFIRMATIONS: 12,
    ETHO_CONFIRMATIONS: 22,
    token_chainID: 8453,
    mainnet_chainID: GLOBAL.mainnet_chainID,
    provider_TOKEN: 'https://mainnet.base.org',
    provider_ETHO: GLOBAL.provider_ETHO,
    ETHO_ETH_MIN_DEPOSIT: 1,
    ETH_MIN_WITHDRAWAL: 1,
  },
  BSC: {
    IDENT: "BSC",
    EXPLORER_BSC_TOKEN: "https://bscscan.com/token/",
    EXPLORER_ETH_TX: "https://bscscan.com/tx/",
    EXPLORER_BSC_ADDRESS: "https://bscscan.com/address/",
    EXPLORER_ETHO_TX: GLOBAL.EXPLORER_ETHO_TX,
    EXPLORER_ETHO_ADDRESS: GLOBAL.EXPLORER_ETHO_ADDRESS,
    pancakeSwapLogo: require('../assets/imgs/etho/pancakeswap.png'),
    etherscanLogo: require('../assets/imgs/etho/bscscan.svg'),
    tokenLogo: require("../assets/imgs/etho/bsclogo.png"),
    erc20Logo: require("../assets/imgs/etho/bep20.png"),
    DEX_ADDRESS: "https://pancakeswap.finance/info/v3/pairs/0x29cf9b79994eb6d6555e490b5b4ae0fb78179d06",
    TKN_NETWORK_NAME: "BSC Network",
    ETH_PRICE_URL: "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=bnb",
    address_TKN: "0x48b19b7605429aCAa8Ea734117F39726a9AAb1f9",
    address_BA: "0xcC00694b3D51A53b8d2a96285496675cdbeD97fA",
    DEV_WALLET : '0x1509c8F22b7a14bab4EA829324BFcc44371A5667',
    address_ORCL: GLOBAL.address_ORCL,
    address_SIGNER: "0x8b52F46A52D86C131222EE14167Da6a847bdB84a",
    address_VERIFIER: "0x488f9b27e7a7F8a74437DBb933E014F428E44343",
    address_SIGNER_authority: GLOBAL.address_SIGNER_authority,
    address_DEVFUND: GLOBAL.address_DEVFUND,
    TOKEN_CONFIRMATIONS: 12,
    ETHO_CONFIRMATIONS: 22,
    token_chainID: 56,
    mainnet_chainID: GLOBAL.mainnet_chainID,
    provider_TOKEN: 'https://bsc-dataseed.binance.org',
    provider_ETHO: GLOBAL.provider_ETHO,
    ETHO_ETH_MIN_DEPOSIT: 3,
    ETH_MIN_WITHDRAWAL: 1,
  },
  ETHO: {
    DEV_WALLET : '0xBA57dFe21F78F921F53B83fFE1958Bbab50F6b46',
  },
  
  MULTISIG_NAMES : {
    "0x6830EBcA6Da5277f2154aB94E36F807B4Af6Cc7a": "Allan",
    "0xA5D20A1e38ee9dFb04e518ebBd5815d9cA3ab321": "Chris",
    "0x66D35434Fa243F28c0B121BCecf5AeB3D699fd4C": "Def",
    "0xfb7f3074B16E5B2eF73C775ADCD3bf48B8cFF959": "Dox",
    "0x87F0EA8C325caFBBB8BB748756ef147b7A50405d": "Dylie",
    "0x68EfF0F98Cbe958e8865684d3Dca06a78b0dA46e": "Exlo",
    "0x83ba450AD84e1De69E577F1a62d75dc9bF6B8118": "Porfavor",
    "0x8278617661CbE273aF2D81972ae89037526e9d72": "Saiyan",
    "0xEA1518210800C65ab83d3a837794bd479BE6BEB1": "BTMC",
    "0x59f5607C5b3fD2799E2124766888204611D474fD": "Kazak",
  },
  settings: GLOBAL,
  // Add other networks as needed
};

export default networks;

