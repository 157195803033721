<template>
  <header class="header">
    <div class="container">
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/imgs/etho/logo.png" alt="Etho Protocol Logo" class="header-logo">
          <img src="@/assets/imgs/etho/etho_title.png" alt="Etho Protocol Title" class="header-logo2">
        </router-link>
      </div>
      <nav class="main-nav">
        <ul>
          <li class="nav-item headerBtn">
            <router-link class="nav-link" to="/Develop">Develop</router-link>
          </li>
          <li class="nav-item headerBtn">
            <router-link class="nav-link" to="/Upload">Upload</router-link>
          </li>
          <!-- <li class="nav-item headerBtn">
            <router-link class="nav-link" to="/Wallets">Wallets</router-link>
          </li> -->
          <!-- <li class="nav-item headerBtn">
            <a href="https://ethoprotocol.com/etho-protocol-white-paper-may-2024/" class="nav-link">Whitepaper</a>
          </li> -->
          <li class="nav-item headerBtn">
            <router-link class="nav-link" to="/Whitepaper">Whitepaper</router-link>
          </li>
          <!-- <li class="nav-item headerBtn">
            <a href="https://bridge.ethoprotocol.com/" target="_blank" rel="noopener noreferrer" class="nav-link">Bridge</a>
          </li> -->
          <li class="nav-item headerBtn">
            <router-link class="nav-link" to="/Bridge">Bridge</router-link>
          </li>
          <li class="nav-item headerBtn">
            <router-link class="nav-link" to="/Team">Team</router-link>
          </li>
        </ul>
      </nav>
      <div class="social-icons">
        <a href="https://twitter.com/ethoprotocol" target="_blank" rel="noopener noreferrer" class="social-icon">
          <img src="@/assets/imgs/misc/twitter.svg" alt="Twitter Icon" class="icon-img" />
        </a>

        <a href="https://discord.com/invite/ZEHVYqBcDw" target="_blank" rel="noopener noreferrer" class="social-icon">
          <img src="@/assets/imgs/misc/discord.svg" alt="Discord Icon" class="icon-img" />
        </a>

        <a href="https://t.me/ethoprotocol" target="_blank" rel="noopener noreferrer" class="social-icon">
          <img src="@/assets/imgs/misc/telegram.svg" alt="Telegram Icon" class="icon-img" />
        </a>

        <a href="https://github.com/Ether1Project" target="_blank" rel="noopener noreferrer" class="social-icon">
          <img src="@/assets/imgs/misc/github.svg" alt="Github Icon" class="icon-img" />
        </a>
      </div>
    </div>
  </header>

  <AppConsent/>
</template>

<script>

import AppConsent from '../views/AppConsent.vue';

export default {
  name: 'AppHeader',
  components: {
    AppConsent
  }
}
</script>
<style scoped>
.header-logo2 {
  padding: 9px;
  padding-left:42px;
  filter:saturate(0.8) brightness(2.2) contrast(2.2);
}

.icon-img {
  filter: invert(100%) brightness(200%) contrast(200%);
}

.header {
  background: linear-gradient(270deg, #2781b1, #33325d);
  /* background: linear-gradient(90deg, #1a1a1d, #4e4e50); */
  padding: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #00d1b2;
  animation: fadeIn 1.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo img {
  height: 80px;
  transition: transform 0.3s ease-in-out;
}

.logo img:hover {
  transform: scale(1.1);
}

.main-nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  padding-right: 20px;
}

.main-nav ul li {
  display: inline-block;
}

.nav-link {
  text-decoration: none;
  color: #f8f9fa;
  font-weight: 500;
  position: relative;
  padding: 5px;
  transition: color 0.3s, transform 0.3s;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #00d1b2;
  left: 0;
  bottom: -5px;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::after {
  transform: scaleX(1);
}

.nav-link:hover {
  color: #00d1b2;
  transform: translateY(-2px);
}

.social-icons {
  display: flex;
  gap: 5px;
}

.social-icon img {
  width: 32px;
  height: 32px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.8;
  margin-right:6px;
}

.social-icon img:hover {
  transform: scale(1.15);
  filter: invert(37%) sepia(91%) saturate(616%) hue-rotate(144deg) brightness(159%) contrast(110%);
  opacity: 1;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo img {
    height: 60px;
    margin-bottom: 10px;
  }

  .main-nav ul {
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }

  .main-nav ul li {
    width: 100%;
    text-align: left;
  }

  /* Make the headerBtn elements full width on mobile */
  .main-nav {
    width: 100%;
  }

  .nav-link {
    display: flex;
    justify-content: center; /* Centers text horizontally */
    align-items: center; /* Centers text vertically */
    height: 100%; /* Ensure it takes the full height of the parent */
    text-align: center; /* Additional fallback for centering text */
    /* padding: 10px 20px; */
    color: #f8f9fa; /* Text color */
    text-decoration: none; /* Remove underline */
    font-weight: 500; /* Font weight */
    position: relative; /* For positioning effects */
    transition: color 0.3s, transform 0.3s;
    height: 20px;
  }


  .nav-link {
    padding: 10px 0;
    font-size: 18px;
    width: 100%;
  }

  .social-icons {
    justify-content: center;
    margin-top: 15px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .logo img {
    height: 50px;
  }

  .nav-link {
    font-size: 16px;
  }

  .social-icon img {
    width: 28px;
    height: 28px;
  }
}
</style>
