<template>
    <header>
      <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
        <div class="container">
          <div class="navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <a
                  href="https://explorer.ethoprotocol.com/accounts"
                  target="_blank"
                  class="btn btn-primary mb-2 nav-link"
                >
                  {{ ticker.mainnet_tokens }} ETHO
                  <img
                    src="https://raw.githubusercontent.com/Ether1Project/ETHO-Protocol-Branding/master/NewLogo2024/etho20.png"
                    class="tokenImg"
                  />
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://etherscan.io/token/0x0b5326da634f9270fb84481dd6f94d3dc2ca7096#balances"
                  target="_blank"
                  class="btn btn-primary mb-2 nav-link"
                >
                  {{ ticker.eth_tokens }} ETH
                  <img
                    src="https://raw.githubusercontent.com/Ether1Project/ETHO-Protocol-Branding/master/NewLogo2024/erc20.png"
                    class="tokenImg"
                  />
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://basescan.org/token/0x8b52F46A52D86C131222EE14167Da6a847bdB84a#balances"
                  target="_blank"
                  class="btn btn-primary mb-2 nav-link"
                >
                  {{ ticker.base_tokens }} BASE
                  <img
                    src="https://raw.githubusercontent.com/Ether1Project/ETHO-Protocol-Branding/master/NewLogo2024/base20.png"
                    class="tokenImg"
                  />
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://bscscan.com/token/0x48b19b7605429acaa8ea734117f39726a9aab1f9#balances"
                  target="_blank"
                  class="btn btn-primary mb-2 nav-link"
                >
                  {{ ticker.bnb_tokens }} BNB
                  <img
                    src="https://raw.githubusercontent.com/Ether1Project/ETHO-Protocol-Branding/master/NewLogo2024/bep20.png"
                    class="tokenImg"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  </template>
  
  <script>
    import networks from "../scripts/settings";
    import Web3 from "web3";
    /* eslint-disable */
    export default {
      name: 'TickerComponent',
      data() {
        return {
          tickerMessage: 'loading...',
          mainnetBalance: BigInt(0),
          wrappedEthBalance: BigInt(0),
          wrappedBaseBalance: BigInt(0),
          wrappedBscBalance: BigInt(0),
          ticker: {
            base_tokens: "0",
            eth_tokens: "0",
            bnb_tokens: "0",
            mainnet_tokens: "0",
          },
          provider_ETHO: networks.settings.provider_ETHO,
          wrapped_etho_eth_contract: '0x370f083A3c4794DDBb49cfB4E7C7e09B37d57545',
          wrapped_etho_base_contract: '0xF3c40b22e2505ad2D7C9254b5479E3e9046F0f16',
          wrapped_etho_bsc_contract: '0xcC00694b3D51A53b8d2a96285496675cdbeD97fA',
          latestBlock: null,
          monitary_block: [
            BigInt(1000000), BigInt(1000000), BigInt(700000), BigInt(300000),
            BigInt(800000), BigInt(200000), BigInt(1000000), BigInt(1000000),
            BigInt(1000000), BigInt(1000000), BigInt(150000), BigInt(600000),
            BigInt(250000), BigInt(1000000), BigInt(1000000), BigInt(1000000),
            BigInt(1000000)
          ],
          monitary_mining: [
            BigInt(10000000000000000000), BigInt(8000000000000000000),
            BigInt(6400000000000000000), BigInt(5600000000000000000),
            BigInt(4500000000000000000), BigInt(2400000000000000000),
            BigInt(1800000000000000000), BigInt(1250000000000000000),
            BigInt(800000000000000000), BigInt(600000000000000000),
            BigInt(10000000000000000000), BigInt(300000000000000000),
            BigInt(300000000000000000), BigInt(300000000000000000),
            BigInt(300000000000000000), BigInt(300000000000000000),
            BigInt(300000000000000000)
          ],
          monitary_node: [
            BigInt(2000000000000000000), BigInt(2000000000000000000),
            BigInt(2000000000000000000), BigInt(2800000000000000000),
            BigInt(2600000000000000000), BigInt(2400000000000000000),
            BigInt(1900000000000000000), BigInt(1400000000000000000),
            BigInt(1000000000000000000), BigInt(800000000000000000),
            BigInt(650000000000000000), BigInt(650000000000000000),
            BigInt(650000000000000000), BigInt(500000000000000000),
            BigInt(500000000000000000), BigInt(550000000000000000),
            BigInt(550000000000000000)
          ],
          monitary_dev: [
            BigInt(1000000000000000000), BigInt(1000000000000000000),
            BigInt(1000000000000000000), BigInt(1000000000000000000),
            BigInt(1000000000000000000), BigInt(1000000000000000000),
            BigInt(1000000000000000000), BigInt(800000000000000000),
            BigInt(650000000000000000), BigInt(500000000000000000),
            BigInt(350000000000000000), BigInt(350000000000000000),
            BigInt(350000000000000000), BigInt(250000000000000000),
            BigInt(200000000000000000), BigInt(150000000000000000),
            BigInt(150000000000000000)
          ]
        };
      },
      methods: {
        async init() {
          this.web3 = new Web3(this.provider_ETHO);
  
          this.wrappedEthBalance = await this.fetchBalance(this.wrapped_etho_eth_contract);
          this.wrappedBaseBalance = await this.fetchBalance(this.wrapped_etho_base_contract);
          this.wrappedBscBalance = await this.fetchBalance(this.wrapped_etho_bsc_contract);
          this.latestBlock = await this.web3.eth.getBlockNumber();
          let mainnetBalanceInEth = await this.getTotalSupply(this.latestBlock);
  
          // Calculate mainnet balance after subtracting wrapped balances
          this.mainnetBalance = mainnetBalanceInEth - this.wrappedEthBalance - this.wrappedBaseBalance - this.wrappedBscBalance;
  
          this.updateTicker();
        },
        async fetchBalance(contractAddress) {
          return BigInt(await this.web3.eth.getBalance(contractAddress));
        },
        async getTotalSupply(latestBlock) {
          let othercoins = BigInt(21563938000000000000000000);
          let miningcoins = BigInt(0);
          const uncle_reward_rate = 1.06;
          let result = BigInt(latestBlock);
          let i = 0;
  
          for (i = 0; i < this.monitary_block.length - 1; i++) {
            if (result - this.monitary_block[i] < BigInt(0)) break;
  
            result -= this.monitary_block[i];
            miningcoins += this.monitary_mining[i] * this.monitary_block[i];
            othercoins +=  (this.monitary_node[i] + this.monitary_dev[i]) * this.monitary_block[i];
          }
  
          miningcoins += this.monitary_mining[i] * result;
          miningcoins = BigInt(Math.floor(Number(miningcoins) * uncle_reward_rate));
          othercoins +=  (this.monitary_node[i] + this.monitary_dev[i]) * result;
  
          miningcoins -= this.monitary_mining[0];
          othercoins -= this.monitary_node[0] + this.monitary_dev[0];
          return (miningcoins + othercoins);
        },
        numberWithCommas(x) {
          // Convert to string and format for thousands separators
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        updateTicker() {
          this.ticker.mainnet_tokens = this.formatBalance(this.mainnetBalance);
          this.ticker.eth_tokens = this.formatBalance(this.wrappedEthBalance);
          this.ticker.base_tokens = this.formatBalance(this.wrappedBaseBalance);
          this.ticker.bnb_tokens = this.formatBalance(this.wrappedBscBalance);
        },
        formatBalance(balance, isRaw = false) {
          let _val = isRaw ? balance : parseInt(this.web3.utils.fromWei(balance.toString(), 'ether'));
          return this.numberWithCommas(_val);
        }
      },
      mounted() {
        this.init();
      }
    };
  </script>
  
  <style scoped>
  .tokenImg {
    margin-left:2px;
    width: 64px;
  }
  
  .btn-primary {
    background: linear-gradient(#287ead, #313f6a) !important;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
                0px 24px 38px 3px rgba(0, 0, 0, 0.14),
                0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  
  .btn-primary:hover {

    color: inherit;
    box-shadow: none;
  }
  
  .navbar {
    background: linear-gradient(270deg, #2781b1, #33325d) !important;
    /* background: linear-gradient(270deg, #2781b1, #0b496a) !important; */
  }
  
  .navbar-brand img {
    max-width: 100%;
    height: auto;
  }
  
  .navbar-nav {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    justify-content: space-between;
    width: 100%;
  }
  
  .nav-item {
    margin-right: 10px;
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .navbar-nav {
      flex-direction: column;
      align-items: center;
    }
  
    .nav-item {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }
  
    .nav-link {
      width: 100%;
      display: block;
    }
  }
  </style>
