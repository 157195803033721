// src/router/routes.js
const routes = [
    {
      path: '/',
      name: 'AppHome',
      component: () => import(/* webpackChunkName: "home" */ '../views/AppHome.vue'),
    },
    {
      path: '/about',
      name: 'AppAbout',
      component: () => import(/* webpackChunkName: "about" */ '../views/AppAbout.vue'),
    },
    {
      path: '/upload',
      name: 'AppUpload',
      component: () => import(/* webpackChunkName: "upload" */ '../views/AppUpload.vue'),
    },
    {
      path: '/wallets',
      name: 'AppWallets',
      component: () => import(/* webpackChunkName: "wallets" */ '../views/AppWallets.vue'),
    },
    {
      path: '/develop',
      name: 'AppDevelop',
      component: () => import(/* webpackChunkName: "develop" */ '../views/AppDevelop.vue'),
    },
    {
      path: '/bridge',
      name: 'AppBridge',
      component: () => import(/* webpackChunkName: "bridge" */ '../views/AppBridge.vue'),
    },
    {
      path: '/team',
      name: 'AppTeam',
      component: () => import(/* webpackChunkName: "team" */ '../views/AppTeam.vue'),
    },
    {
      path: '/whitepaper',
      name: 'AppPdfviewer',
      component: () => import(/* webpackChunkName: "pdfviewer" */ '../views/AppPdfviewer.vue'),
    },
  ];
  
  module.exports = routes;
  