<template>
  <div v-if="!cookiesAccepted" class="cookie-consent-banner" :class="{'show-banner': showBanner}">
    <p>We use cookies to enhance your experience. By continuing to visit this site, you accept our use of cookies.</p>
    <button @click="declineCookies">Decline</button>
    <button @click="acceptCookies">Accept</button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const cookiesAccepted = ref(false);
    const showBanner = ref(false);
    // const instance = getCurrentInstance();

    onMounted(() => {
      // Check if cookies have already been accepted when the component is mounted
      cookiesAccepted.value = document.cookie.includes('cookiesAccepted=true');

      // Delay showing the banner by 1 second
      if (!cookiesAccepted.value) {
        setTimeout(() => {
          showBanner.value = true;
        }, 800); // 1 second delay
      }
    });

    const acceptCookies = () => {
      // Set the cookie to accepted and update the state
      document.cookie = 'cookiesAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
      cookiesAccepted.value = true;
    };

    const declineCookies = () => {
      // Set the cookie to declined and update the state
      document.cookie = 'cookiesAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
      cookiesAccepted.value = true;
    };

    return {
      cookiesAccepted,
      showBanner,
      acceptCookies,
      declineCookies,
    };
  }
};
</script>


<style scoped>
.cookie-consent-banner {
  position: fixed;
  bottom: -101px; /* Start outside the screen */
  width: 100%;
  font-size: 14px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  text-align: center;
  padding: 1.5em 1em;
  z-index: 1000;
  line-height: 1.5;
  opacity: 0; /* Start with no opacity */
  transition: bottom 1s ease-out, opacity 1s ease-out;
}

.cookie-consent-banner.show-banner {
  bottom: 0; /* Move it into the view */
  opacity: 1; /* Fade it in */
}

.cookie-consent-banner p {
  margin: 0;
  padding: 0;
}

.cookie-consent-banner button {
  margin-top: 6px;
  margin-left: 1em;
  padding: 0.2em 1em;
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  font-size: 14px;
}
</style>
