// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Your router configuration

import { createHead } from '@vueuse/head';
import VueGtag from 'vue-gtag';
import VueTyped from 'vue3-typed-js';
import VueCookies from 'vue3-cookies';

import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'vuetify/styles';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom.scss'; // Your custom styles

// Vuetify instance
const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App);

// Set up plugins
app.use(router); // Ensure router is used
app.use(createHead()); // SEO-friendly head management
app.use(VueTyped); // Use Typed.js for typing animations
app.use(vuetify); // Use Vuetify for UI components
app.use(VueCookies, {
  expireTimes: '30d',
  path: '/',
  secure: true,
  sameSite: 'Lax',
});

const cookiesDenied = document.cookie.includes('cookiesAccepted=false');
if (!cookiesDenied) {
  // Google Analytics (GA4) with Vue Router page tracking
  app.use(VueGtag, {
    config: {
      id: 'G-Q7MPD52GST', // Replace with your actual GA ID
    },
    appName: 'ETHO WEBSITE',
    pageTrackerEnabled: true, // This will track page views on route changes
  }, router);
}

// Mount the app
app.mount('#app');
