<template>
  <PartnerSection />
  <TickerSection />

  <footer class="footer-container">
    <div class="newsletter-container">
      <h4>Subscribe to Our Newsletter</h4>
      <div class="tnp tnp-widget-minimal">
        <form class="tnp-form" @submit.prevent="showThankYouMessage">
          <input type="hidden" name="nr" value="widget-minimal" />
          <input
            class="tnp-email"
            type="email"
            required="required"
            name="ne"
            v-model="email"
            placeholder="Enter your email"
          />
          <input class="tnp-submit" type="submit" value="Subscribe" />
        </form>
      </div>
    </div>

    <div class="nav-container">
      <nav class="main-nav">
        <ul>
          <li><a href="https://nodes.ethoprotocol.com/">Nodes</a></li>
          <li><a href="https://nft.ethoprotocol.com/">NFT Marketplace</a></li>
          <li><a href="https://ethoprotocol.com/Wallets">Etho Wallets</a></li>
          <li><a href="https://ethoprotocol.com/Bridge">Bridge</a></li>
          <li><a href="https://explorer.ethoprotocol.com/">Explorer</a></li>
          <li><a href="https://ethoprotocol.com/Upload">Uploads</a></li>
        </ul>
      </nav>
    </div>

    <div class="logo-container">
      <a href="/">
        <img
          src="@/assets/imgs/etho/logo.png"
          alt="Etho Protocol Logo"
        />
      </a>
    </div>

    <div class="contact-container">
      <ul>
        <li>
          <a href="mailto:team@ethoprotocol.com">Email: team@ethoprotocol.com</a>
        </li>
      </ul>
    </div>
  </footer>

  <div class="text-center">
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      vertical
      color="transparent" 
      variant="flat"
      location="center"
    >
      <v-sheet
        class="pa-7 text-center"
        elevation="4"
        max-width="600"
        rounded="lg"
        width="100%"
        style="border:3px solid #1da1f2;"
      >
        <img
          src="@/assets/imgs/misc/email.svg"
          style="width:80px;"
          alt="Etho Protocol Logo"
        />

        <h2 class="text-h5 mb-6">Added to Newsletter!</h2>

        <p class="mb-4 text-medium-emphasis text-body-2">
          Thank you for signing up:
          <br>
          <a class="text-decoration-none text-info" href="#"><b>{{ this.email }}</b></a>
          <br>

          is now registered!
        </p>

        <v-divider class="mb-4"></v-divider>
      </v-sheet>
    </v-snackbar>
  </div>
</template>

<script>
import TickerSection from '../components/TickerSection.vue';
import PartnerSection from '../components/PartnerSection.vue';

export default {
  name: "AppFooter",
  components: {
    TickerSection,
    PartnerSection,
  },
  data() {
    return {
      email: '',
      snackbar: false,
    };
  },
  methods: {
    showThankYouMessage() {
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.footer-container {
  background: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.newsletter-container {
  margin-bottom: 20px;
  text-align: center;
}

.newsletter-container h4 {
  font-size: 18px;
  color: #f2f2f2;
}

.tnp-form {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.tnp-email {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #ededed;
  border-radius: 5px;
  font-size: 14px;
  width: 260px;
}

.tnp-submit {
  padding: 5px 15px;
  background-color: #1da1f2;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tnp-submit:hover {
  background-color: #0d95e8;
}

.nav-container {
  margin-bottom: 20px;
  border-top: 2px solid white;
  padding-top: 10px;
}

.main-nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.main-nav a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.main-nav a:hover {
  color: #1da1f2;
}

.logo-container img {
  max-width: 100px;
  margin-bottom: 20px;
}

.contact-container ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.contact-container a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.contact-container a:hover {
  color: #1da1f2;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-container {
    padding: 15px;
  }

  .tnp-email {
    width: 220px;
    font-size: 13px;
  }

  .nav-container {
    margin-bottom: 15px;
  }

  .main-nav ul {
    gap: 10px;
    padding: 0;
  }

  .main-nav a {
    font-size: 14px;
  }

  .logo-container img {
    max-width: 80px;
  }

  .contact-container {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .tnp-email {
    width: 180px;
    font-size: 12px;
  }

  .tnp-submit {
    padding: 4px 10px;
  }

  .main-nav a {
    font-size: 12px;
  }

  .logo-container img {
    max-width: 70px;
  }
}
</style>
